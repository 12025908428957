<template>
  <div class="footer-component">
    <div class="container-wrapped">
      <div class="row">
        <div class="col">
          <span>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam
            fringilla nec mauris sed venenatis. Aliquam erat volutpat.
            Pellentesque luctus mauris in erat ullamcorper scelerisque. Class
            aptent taciti sociosqu ad litora torquent per conubia nostra, per
            inceptos himenaeos. Mauris pulvinar orci vitae sem iaculis, non
            faucibus eros sollicitudin. Vestibulum ante ipsum primis in faucibus
            orci luctus et ultrices posuere cubilia curae; Nullam nec congue
            nulla. Sed tincidunt, dolor a euismod viverra, mi ligula hendrerit
            turpis, id mollis orci leo vel massa. Donec posuere libero nulla, in
            sodales ex aliquet in. In non quam vel mauris vestibulum rhoncus at
            id dui. In ac scelerisque velit. Vestibulum eu velit mauris. Nam sed
            sodales metus. Donec eu risus nisi. Praesent interdum nibh hendrerit
            ex commodo, eget interdum leo viverra. Donec facilisis in est sit
            amet sollicitudin. Proin viverra magna eu dolor hendrerit, nec
            tristique tortor euismod. In rutrum nec massa a dapibus. Sed eget
            tincidunt diam. Suspendisse nisi elit, viverra et dictum sit amet,
            maximus eget urna. Maecenas vulputate, libero quis pharetra
            consectetur, sapien elit commodo eros, ac condimentum lorem nulla
            fringilla diam. Morbi ut leo in leo mattis consectetur. Praesent nec
            dui ut lorem viverra facilisis sed ut dolor. Donec sagittis iaculis
            massa sit amet lacinia.
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'Footer'
});
</script>

<style scoped lang="scss">
.footer-component {
}
</style>
